import * as Sentry from "@sentry/react";
import { getEnvVar } from "./index";

const logUser = (info: object) => {
	Sentry.setUser(info);
};

const initSentry = () => {
	const enableSentry = getEnvVar("sentryEnable") === "true";

	if (!enableSentry) {
		console.info("Sentry feature is not enabled");
		return;
	}

	try {
		Sentry.init({
			dsn: getEnvVar("sentryDsn"),
			integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
	} catch (error) {
		console.error("Failed to init Sentry");
		console.error(error);
	}
};

export { initSentry, logUser };
