import axios from "axios";
import { clearAllLocalStorage, setItem, showNotifications, removeKeyStorage, getItemStorage } from "@/shared/utils";
import { getEnvVar } from "@/config";
import { v4 as uuidv4 } from "uuid";

const generateIdempotencyKey = () => {
	const currentIdempotencyKey = getItemStorage("idempotencyKey");

	if (currentIdempotencyKey) {
		return currentIdempotencyKey;
	}

	const idempotencyKey = uuidv4();

	setItem("idempotencyKey", idempotencyKey);
	return idempotencyKey;
};

const TIMEOUT = 5000;

const legancyApiUrl = getEnvVar("apiBaseUrl");
const newApiUrl = getEnvVar("apiNewUrl");

const axiosInstance = (isLegancyApi = true) => {
	const baseURL = isLegancyApi ? legancyApiUrl : newApiUrl;

	const api = axios.create({ baseURL });
	api.interceptors.request.use(
		(config) => {
			config.timeout = TIMEOUT;
			const accessToken = getItemStorage<string>("access_token");

			if (accessToken) {
				config.headers.Authorization = `Bearer ${accessToken}`;
			}
			return config;
		},
		(err) => {
			if (err?.data?.message) {
				showNotifications({ title: "Server Error", message: err.data.message, successNotification: false });
			}

			return Promise.reject(new Error(err));
		}
	);

	api.interceptors.response.use(
		(response) => {
			if (response.status === 200 || response.status === 201) {
				removeKeyStorage("idempotencyKey");
			}

			return response;
		},
		async (error) => {
			if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
				showNotifications({ title: "Request Aborted", message: error.response.message, successNotification: false });
			}

			if (error.response?.status === 401) {
				showNotifications({
					title: "Unauthorized request",
					message: error.response.message,
					successNotification: false,
				});

				clearAllLocalStorage();
				window.location.href = "/login";
			}

			if (error.response?.status === 400) {
				showNotifications({ title: "Server Error", message: error.response.data.message, successNotification: false });
			}

			// refresh token status
			if (error.response?.status === 4598) {
				const refreshToken = getItemStorage<string>("refresh_token");

				if (!refreshToken) {
					clearAllLocalStorage();
					window.location.href = "/login";

					return;
				}

				// send request
				const res = await axios.get("/refresh-token", {
					headers: {
						Authorization: `Bearer ${refreshToken}`,
					},
				});

				if (res.status === 200) {
					// Example how to fill localstorage
					// localStorage.setItem("accessToken", res.result.accessToken);
					// localStorage.setItem("refreshToken", res.result.refreshToken);
					// axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${res..accessToken}`;
					// window.location.href = "/";
				}
			}

			return Promise.reject(new Error(error));
		}
	);

	return api;
};

// const axiosInstance = axios.create({ baseURL });

export { axiosInstance, generateIdempotencyKey };
