import { useRoutes } from "react-router-dom";
import { lazy, Suspense } from "react";
// pages
const Home = lazy(() => import("@/pages/Home"));
const NotFound = lazy(() => import("@/pages/NotFound"));
const Result = lazy(() => import("@/pages/Result"));
const ResultVideo = lazy(() => import("@/pages/ResultVideo"));
const HealthAdvisor = lazy(() => import("@/pages/HealthAdvisor"));
const HealthAdvisorDetails = lazy(() => import("@/pages/HealthAdvisorDetails"));
const Payment = lazy(() => import("@/pages/Payment"));
const BookingHealthAdvisor = lazy(() => import("@/pages/BookingHealthAdvisor"));
const ThankYou = lazy(() => import("@/pages/ThankYou"));

// Layouts
const Layout = lazy(() => import("@/layouts/Layout"));

export const Router = () => {
	const routes = useRoutes([
		{
			path: "*",
			element: <NotFound />,
		},
		{
			path: "/",
			element: <Layout />,
			children: [
				{
					path: "/",
					element: <Home />,
				},
				{
					path: "/result",
					element: <Result />,
				},
				{
					path: "/result-video",
					element: <ResultVideo />,
				},
				{
					path: "/health-advisor",
					element: <HealthAdvisor />,
				},
				{
					path: "/book-health-advisor",
					element: <BookingHealthAdvisor />,
				},
				{
					path: "/thank-you",
					element: <ThankYou />,
				},
				{
					path: "/health-advisor-detail/:id",
					element: <HealthAdvisorDetails />,
				},
				{
					path: "/sign-up/:id/:productId",
					element: <Payment />,
				},
			],
		},
	]);

	return <Suspense fallback={<div>Loading...</div>}>{routes}</Suspense>;
};
