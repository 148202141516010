import { getEnvVar } from "./index";

const isServiceWorkerEnabled = getEnvVar("serviceWorkerEnable") === "true";

const checkServiceWorkerSupport = () => {
	let isSupported: boolean = true;

	if (!navigator.serviceWorker) {
		isSupported = false;

		return isSupported;
	}

	if (!("serviceWorker" in navigator)) {
		isSupported = false;

		return isSupported;
	}

	return isSupported;
};

const removeServiceWorkers = async (): Promise<void> => {
	if (!checkServiceWorkerSupport()) {
		return;
	}

	const registrations = await navigator.serviceWorker.getRegistrations();

	for (const registration of registrations) {
		await registration.unregister();
	}
};

const registerServiceWorkers = async () => {
	if (!checkServiceWorkerSupport() && !isServiceWorkerEnabled) {
		return;
	}

	await navigator.serviceWorker.register(`./sw.js`, {
		scope: "/",
	});
};

export { removeServiceWorkers, registerServiceWorkers, checkServiceWorkerSupport };
