import { getEnvVar } from "@/config/environment";
import { onLCP, onINP, onCLS, LCPMetric, INPMetric, CLSMetric } from "web-vitals";

const sendToAnalytics = (metric: unknown) => {
	const body = JSON.stringify(metric);

	if (navigator?.sendBeacon?.("/analytics", body)) {
		fetch("/analytics", { body, method: "POST", keepalive: true });
	}
};

const initWebVitals = () => {
	const enableSentry = getEnvVar("webVitalsEnable") === "true";

	if (!enableSentry) {
		console.log("Web Vitals are not enabled");
		return;
	}

	reportWebVitals(console.log);
};

const reportWebVitals = (callback: (...args: LCPMetric[] | INPMetric[] | CLSMetric[]) => LCPMetric | void) => {
	onLCP(callback);
	onINP(callback);
	onCLS(callback);
};

export { initWebVitals, reportWebVitals, sendToAnalytics };
