import { getEnvVar } from "@/config";
import crypto from "crypto-js";
import DOMPurify from "dompurify";

const SECRET_KEY = getEnvVar("secretKey");

const encryptData = (value: string) => {
	const sanitizeValue = DOMPurify.sanitize(value);

	return crypto.AES.encrypt(sanitizeValue, SECRET_KEY).toString();
};

const decryptData = (value: string) => {
	const sanitizeValue = DOMPurify.sanitize(value);
	const bytes = crypto.AES.decrypt(sanitizeValue, SECRET_KEY);
	const originalText = bytes.toString(crypto.enc.Utf8);

	return originalText;
};

export { encryptData, decryptData };
