import { Manager, ManagerOptions, Socket as SocketType } from "socket.io-client";
import { getEnvVar } from "./environment";
import { DefaultEventsMap } from "@socket.io/component-emitter";

const isWebSocketEnabled = getEnvVar("socketEnable") === "true";

class WebSocket {
	private manager;
	public socket: SocketType<DefaultEventsMap, DefaultEventsMap> | undefined;
	private static _instance: WebSocket | null = null;

	private constructor(opts?: Partial<ManagerOptions>) {
		try {
			this.manager = new Manager(getEnvVar("socketBaseUrl"), opts);
		} catch (error) {
			console.error(error);
		}
	}

	public static init(opts?: Partial<ManagerOptions>) {
		if (WebSocket._instance === null && isWebSocketEnabled) {
			WebSocket._instance = new WebSocket(opts);
		}

		return WebSocket._instance;
	}

	public disConnected(): void {
		this.socket?.disconnect();
		WebSocket._instance = null;
	}

	public isConnected(): boolean {
		const socket = WebSocket._instance?.createSocket();

		return socket?.connected ?? false;
	}

	public static instance() {
		return WebSocket._instance?.createSocket();
	}

	private createSocket() {
		if (WebSocket._instance === null) {
			return;
		}

		this.socket = this.manager?.socket("/");

		return this.socket;
	}
}

// To get socket methods
// const socket = WebSocket.instance();

export { WebSocket };
