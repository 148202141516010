import React from "react";
import ReactDOM from "react-dom/client";
import App from "@/App.tsx";
import { initSentry, WebSocket, registerServiceWorkers, initWebVitals } from "@/config";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@/assets/scss/main.scss";

import { BrowserRouter } from "react-router-dom";
import "@/i18n";

// Init Sentry
initSentry();

// Init web vitals
initWebVitals();

// Init WebSocket
WebSocket.init({ autoConnect: true });

// Init Service Worker
registerServiceWorkers();

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
