import { encryptData, decryptData } from "@/shared/utils";
import DOMPurify from "dompurify";

const getItemStorage = <T>(key: string, decryptValue = false, onLocalStorage = true): T | undefined => {
	try {
		const browserStorage = onLocalStorage ? localStorage : sessionStorage;

		const sanitizeValue = DOMPurify.sanitize(browserStorage.getItem(key) ?? "");
		const value = decryptValue ? decryptData(sanitizeValue) : sanitizeValue;
		return sanitizeValue ? JSON.parse(value) : null;
	} catch (error) {
		console.error(error);
	}
};

const setItem = (key: string, value: unknown, encryptValue = false, onLocalStorage = true): void => {
	const stringifyValue = DOMPurify.sanitize(JSON.stringify(value));

	const localStorageValue = encryptValue ? encryptData(stringifyValue) : stringifyValue;

	const browserStorage = onLocalStorage ? localStorage : sessionStorage;

	browserStorage.setItem(key, localStorageValue);
};

const removeKeyStorage = (key: string): void => {
	localStorage.removeItem(key);
	sessionStorage.removeItem(key);
};

const clearAllLocalStorage = (): void => {
	localStorage.clear();
	sessionStorage.clear();
};

export { clearAllLocalStorage, getItemStorage, removeKeyStorage, setItem };
