import { QueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const retryJitter = (retryTime = 3, delay = 1000, withJitter = true) => {
	return {
		retry: (failureCount: number, error: AxiosError) => {
			if (error.response?.status === 404) return false;
			return failureCount < retryTime - 1;
		},
		retryDelay: (attempedIndex: number) => {
			const jitter = 0.5;
			const calcDelay = (attempedIndex + 1) * delay;
			const jitteredDelay = calcDelay + Math.random() * calcDelay * jitter;

			return withJitter ? jitteredDelay : calcDelay;
		},
	};
};

export { queryClient, retryJitter };
