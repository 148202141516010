import { Router } from "./routes";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { queryClient } from "@/api/common";

function App() {
	return (
		<MantineProvider>
			<Notifications />
			<QueryClientProvider client={queryClient}>
				<Router />
				<ReactQueryDevtools />
			</QueryClientProvider>
		</MantineProvider>
	);
}

export default App;
