import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import lang from "./locales";
import ChainedBackend from "i18next-chained-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";
import Backend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";

import { getEnvVar } from "@/config";

const translationApiUrl = getEnvVar("appTranslationApiUrl");

const resources = {
	en: {
		translation: {
			...lang.en,
		},
	},
	no: {
		translation: {
			...lang.no,
		},
	},
};

i18n
	.use(initReactI18next)
	.use(ChainedBackend)
	.use(LanguageDetector)
	.init({
		resources,
		...(translationApiUrl?.length ? {} : { resources }),
		supportedLngs: ["no", "en"],
		...(translationApiUrl
			? {
					backend: {
						backends: [Backend, resourcesToBackend(resources), LocalStorageBackend],
						backendOptions: [
							{
								loadPath: `${translationApiUrl}/api/translations/Web/{{ns}}/{{lng}}/file/`,
								requestOptions: {
									cache: "no-store",
								},
							},
						],
					},
				}
			: {}),
		returnNull: false,
		fallbackLng: "en",
		compatibilityJSON: "v3",
	})
	.catch((e) => {
		throw e;
	});

export default i18n;
