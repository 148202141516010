import { snakeCase } from "lodash";

const envObj = import.meta.env;

const getEnvVar = (key: string): string => {
	const envKey = snakeCase(key);

	const viteVariable = `VITE_${envKey.toUpperCase()}`;
	const variable = envObj?.[viteVariable.toString()] || "";

	return variable;
};

export { envObj, getEnvVar };
