import { notifications } from "@mantine/notifications";
import { NotificationUtil } from "@/shared/types";

const showNotifications = ({
	successNotification = true,
	position = "top-right",
	color,
	title,
	autoClose = 1000,
	className,
	message,
}: NotificationUtil) => {
	if (!message || !title) {
		console.error("Notification should have message or title");

		return;
	}

	let colorNotification = "";

	if (color) {
		colorNotification = color;
	} else {
		colorNotification = successNotification ? "green" : "red";
	}

	notifications.show({
		id: new Date().getTime().toString(),
		title,
		autoClose,
		position,
		className,
		color: colorNotification,
		message,
	});
};

const cleanNotification = () => {
	notifications.clean();
	notifications.cleanQueue();
};

export { showNotifications, cleanNotification };
